import { createTheme } from '@mui/material'

const white = '#ffffff'
const teal = '#92b2af'

const primary = '#346469'
const background = '#cfe6dc'
const secondary = teal
const header = '#638b8c'

const theme = createTheme({
  palette: {
    background: {
      paper: primary,
      default: background,
      header: header,
    },
    primary: {
      contrastText: white,
      main: primary,
    },
    secondary: {
      contrastText: white,
      main: secondary,
    },
    text: {
      primary: white,
    },
  },
  shape: {
    borderRadius: 12,
  },
  spacing: 8,
  typography: {
    fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'],
    fontSize: 16,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          -webkit-font-smoothing: auto;
          -moz-osx-font-smoothing: auto;
        },
        a {
          color: white;
        },
      `,
    },
    MuiPaper: {
      root: {
        backgroundColor: `rgba(52,100,105,0.5)`,
      },
    },
  },
})

export default theme
